const formSettings = {
  fields: [
    {
      key: 'firstName',
      type: 'text',
      isRequired: true,
      name: 'First Name',
      maxLength: 40,
      pattern: /^[a-z ,.'-]+$/i
    },
    {
      key: 'lastName',
      type: 'text',
      isRequired: true,
      name: 'Last Name',
      maxLength: 80,
      pattern: /^[a-z ,.'-]+$/i
    },
    {
      key: 'email',
      type: 'email',
      isRequired: true,
      name: 'Email',
      maxLength: 80,
      pattern:
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
    },
    {
      key: 'phone',
      type: 'phone',
      isRequired: true,
      name: 'Phone',
      maxLength: 40,
      pattern: /^\+?(?!11)\d{10,40}$/i
    },
    {
      key: 'message',
      type: 'textarea',
      isRequired: true,
      name: 'Message',
      maxLength: 1024,
      pattern: /^[ a-z0-9~!@#$%^&*()_+=\-|\\\][{}'’;":/?.>,<\r\n]*$/i
    }
  ],
  submitBtn: {
    text: 'Submit'
  }
};

export default formSettings;
