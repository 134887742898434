import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import PhoneInput from 'react-phone-number-input';
import InputMask from 'react-input-mask';

import 'react-phone-number-input/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import './TextField.sass';

const TextField = ({
  value = '',
  text = 'Text',
  type = 'text',
  className = 'input-text',
  handleChange = () => undefined,
  options = [],
  placeholder = ''
}) => {
  if (type === 'select') {
    return (
      <label className={className}>
        <Select
          value={value}
          isClearable={true}
          placeholder={placeholder || text}
          options={options}
          classNamePrefix="select"
          onChange={(value) => handleChange({ value: value?.value || '' })}
        />
      </label>
    );
  } else if (type === 'date') {
    return (
      <label className={className}>
        <DatePicker
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placeholderText={placeholder || text}
          selected={value}
          popperPlacement="bottom"
          onChange={(date) => handleChange({ value: date })}
          customInput={<InputMask mask="99/99/9999" placeholder="dd/mm/yyyy" />}
        />
      </label>
    );
  } else if (type === 'textarea') {
    return (
      <label className={className}>
        <textarea
          placeholder={placeholder || text}
          value={value}
          onChange={(e) => handleChange(e.target)}
          rows={3}
        />
      </label>
    );
  } else if (type === 'phone') {
    return (
      <label className={className}>
        <PhoneInput
          defaultCountry="US"
          international
          countryCallingCodeEditable={false}
          placeholder={placeholder || text}
          value={value}
          onChange={(e) => handleChange({ value: e })}
        />
      </label>
    );
  }

  return (
    <label className={className}>
      <input
        type={type}
        placeholder={placeholder || text}
        value={value}
        onChange={(e) => handleChange(e.target)}
      />
    </label>
  );
};

TextField.propTypes = {
  value: PropTypes.any,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string
};

export default TextField;
