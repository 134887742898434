const formSettings = {
  fields: [
    {
      key: 'firstName',
      type: 'text',
      isRequired: true,
      name: 'First Name',
      maxLength: 40,
      pattern: /^[a-z ,.'-]+$/i
    },
    {
      key: 'lastName',
      type: 'text',
      isRequired: true,
      name: 'Last Name',
      maxLength: 80,
      pattern: /^[a-z ,.'-]+$/i
    },
    {
      key: 'email',
      type: 'email',
      isRequired: true,
      name: 'Email',
      maxLength: 80,
      pattern:
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
    },
    {
      key: 'phone',
      type: 'phone',
      isRequired: true,
      name: 'Phone',
      maxLength: 40,
      pattern: /^\+?(?!11)\d{10,40}$/i
    },
    // {
    //   key: 'preferredCommunication',
    //   type: 'select',
    //   isRequired: true,
    //   name: 'Preferred Communication',
    //   options: [
    //     {
    //       value: 'Call',
    //       label: 'Call'
    //     },
    //     {
    //       value: 'Email',
    //       label: 'Email'
    //     },
    //     {
    //       value: 'Text',
    //       label: 'Text'
    //     }
    //   ]
    // },
    // {
    //   key: 'fertilityReferral',
    //   type: 'select',
    //   isRequired: true,
    //   name: 'How did you hear about us?',
    //   options: [
    //     {
    //       value: 'Physician Referral',
    //       label: 'Physician Referral'
    //     },
    //     {
    //       value: 'Friend/Family Referral',
    //       label: 'Friend/Family Referral'
    //     },
    //     {
    //       value: 'Google Search / Ad',
    //       label: 'Google Search / Ad'
    //     },
    //     {
    //       value: 'Facebook / Instagram',
    //       label: 'Facebook / Instagram'
    //     },
    //     {
    //       value: 'Other',
    //       label: 'Other'
    //     }
    //   ]
    // },
    // {
    //   key: 'fertilityService',
    //   type: 'select',
    //   isRequired: true,
    //   name: 'Where Would You Like to Start?',
    //   options: [
    //     {
    //       value: 'Fertility Testing',
    //       label: 'Fertility Testing'
    //     },
    //     {
    //       value: 'IUI/IVF Treatment',
    //       label: 'IUI/IVF Treatment'
    //     },
    //     {
    //       value: 'LGBTQ+ Family Planning',
    //       label: 'LGBTQ+ Family Planning'
    //     },
    //     {
    //       value: 'Fertility Preservation',
    //       label: 'Fertility Preservation'
    //     },
    //     {
    //       value: 'Financial Counseling',
    //       label: 'Financial Counseling'
    //     }
    //   ]
    // },
    // {
    //   key: 'preferredPhysician',
    //   type: 'select',
    //   isRequired: false,
    //   name: 'Preferred Physician',
    //   options: [
    //     {
    //       value: 122,
    //       label: 'Dr. Sy Q. Le (Irving)'
    //     },
    //     {
    //       value: 107568,
    //       label: 'Dr. Renju Raj (Arlington)'
    //     }
    //   ]
    // },
    // {
    //   key: 'preferredTime',
    //   type: 'select',
    //   isRequired: false,
    //   name: 'Preferred Time of Day',
    //   options: [
    //     {
    //       value: 'Morning (8:00A - 11:00A)',
    //       label: 'Morning (8:00A - 11:00A)'
    //     },
    //     {
    //       value: 'Afternoon (12:00P - 3:00P)',
    //       label: 'Afternoon (12:00P - 3:00P)'
    //     },
    //     {
    //       value: 'Evening (4:00P - 6:00P)',
    //       label: 'Evening (4:00P - 6:00P)'
    //     }
    //   ]
    // },
    // {
    //   key: 'preferredDayOfWeek',
    //   type: 'select',
    //   isRequired: false,
    //   name: 'Preferred Day of the Week',
    //   options: [
    //     {
    //       value: 'Monday',
    //       label: 'Monday'
    //     },
    //     {
    //       value: 'Tuesday',
    //       label: 'Tuesday'
    //     },
    //     {
    //       value: 'Wednesday',
    //       label: 'Wednesday'
    //     },
    //     {
    //       value: 'Thursday',
    //       label: 'Thursday'
    //     },
    //     {
    //       value: 'Friday',
    //       label: 'Friday'
    //     },
    //     {
    //       value: 'Saturday',
    //       label: 'Saturday'
    //     }
    //   ]
    // },
    {
      key: 'reasonForVisit',
      type: 'textarea',
      isRequired: true,
      name: 'Reason',
      maxLength: 1024,
      pattern: /^[ a-z0-9~!@#$%^&*()_+=\-|\\\][{}'’;":/?.>,<\r\n]*$/i,
      placeholder:
        'We’re here to make sure you feel confident in your next steps forward. Please include anything else we should know.'
    }
  ],
  submitBtn: {
    text: 'Start Your Journey'
  }
};

export default formSettings;
